import { useState } from "react";

import { FeaturedSearchPopover } from "./featured-search-popover";
import { SearchInputIcon } from "./search-input-icon";
import { SearchPopover } from "./search-popover";
import { SearchUIProps } from "./search.interfaces";
import {
  searchContainer,
  searchInput,
  searchPopoverContainer,
  searchPopoverContainerList,
} from "./search.vanilla.css";

import * as Ariakit from "@ariakit/react";
import { Autolayout } from "@hoken/design-system";

export const SearchAsPage = ({
  placeholder = "Search by event, venue or place…",
  themeLight = false,
}: SearchUIProps) => {
  const [open, setOpen] = useState(true);

  const combobox = Ariakit.useComboboxStore({ animated: true, open, setOpen });
  const rawInputValue = combobox.useState("value");

  return (
    <Autolayout justify='center'>
      <div className={searchContainer({ themeLight: themeLight })}>
        <Ariakit.Combobox
          store={combobox}
          placeholder={placeholder}
          className={searchInput}
        />
        <SearchInputIcon query={rawInputValue} />
      </div>
      <Ariakit.ComboboxList store={combobox} className={searchPopoverContainerList}>
        <SearchPopover query={rawInputValue} />
        <FeaturedSearchPopover query={rawInputValue} />
      </Ariakit.ComboboxList>
    </Autolayout>
  );
};

export const Search = ({
  placeholder = "Search by event, venue or place…",
  themeLight = false,
}: SearchUIProps) => {
  const combobox = Ariakit.useComboboxStore({ animated: true });
  const rawInputValue = combobox.useState("value");

  return (
    <>
      <div className={searchContainer({ themeLight: themeLight })}>
        <Ariakit.Combobox
          store={combobox}
          placeholder={placeholder}
          className={searchInput}
        />
        <SearchInputIcon query={rawInputValue} />
        <Ariakit.ComboboxPopover
          store={combobox}
          sameWidth
          flip={false}
          className={searchPopoverContainer}
        >
          <SearchPopover query={rawInputValue} />
          <FeaturedSearchPopover query={rawInputValue} />
        </Ariakit.ComboboxPopover>
      </div>
    </>
  );
};
