import { Link, SuggestEvent, formatDate, formatLocation, pluralize } from "@hoken/core";
// TODO: Refactor to core import
import { useFeaturedEvents } from "@hoken/core/components/events/hooks/useFeaturedEvents";

import { JoinTheWaitlist } from "./join-the-waitlist";
import { SearchPopoverProps } from "./search.interfaces";
import {
  searchPopoverItem,
  searchPopoverItemCenter,
  searchPopoverItemContainer,
  searchPopoverItemDivider,
  searchPopoverItemEnd,
  searchPopoverItemStart,
  searchPopoverItemSuggestEvent,
  searchPopoverItemText,
  searchPopoverItemTitle,
} from "./search.vanilla.css";
import { useSearch } from "./useSearch";

import * as Ariakit from "@ariakit/react";
import { Text } from "@hoken/design-system";

export const FeaturedSearchPopover = ({ query }: SearchPopoverProps) => {
  const { data, isLoading, error } = useFeaturedEvents();
  const { data: queryData } = useSearch(query);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  const featuredEventsResults = data?.events?.length > 0 ? data?.events?.slice(0, 3) : [];
  const noMatchingResults = queryData?.length === 0;
  const origin =
    typeof window !== "undefined" && window.location.origin ? window.location.origin : "";

  if (featuredEventsResults.length > 0) {
    return (
      <>
        <div className={searchPopoverItemTitle}>Featured events</div>
        <div className={searchPopoverItemContainer}>
          {featuredEventsResults.map((featuredEvent, index) => {
            const id = featuredEvent?.id;
            const name = featuredEvent?.name;
            const hotelCount = featuredEvent?.insights?.hotels_count;
            const roomCount = featuredEvent?.insights?.listings_count;
            const startDate = featuredEvent?.start_at;
            const endDate = featuredEvent?.end_at;
            const dateRange = formatDate({ startDate, endDate }).replace(
              /(\b\d+)\s+\-\s+(\d+\b)/g,
              "$1-$2",
            );
            const location = formatLocation({
              city: featuredEvent?.venue?.city,
              state: featuredEvent?.venue?.state,
            });
            const encodedName = name
              ? encodeURIComponent(name?.replace(/ /gi, "-").toLowerCase())
              : null;
            const encodedId = encodeURI(id);
            // Absolute routes to allow search to be rendered on any route
            const eventHref = `${origin}/events/${encodedName}/${encodedId}`;

            return (
              <Link key={`${id}-${index}`} href={eventHref} legacyBehavior>
                <a>
                  <Ariakit.ComboboxItem className={searchPopoverItem}>
                    <div className={searchPopoverItemStart}>
                      <div className={searchPopoverItemText({ typography: "dateRange" })}>
                        {dateRange ? dateRange : <>&#8212; &#8212;</>}
                      </div>
                    </div>
                    <div className={searchPopoverItemCenter}>
                      <div className={searchPopoverItemText({ typography: "event" })}>
                        {name}
                      </div>
                      <div className={searchPopoverItemDivider} />
                      <div className={searchPopoverItemText({ typography: "location" })}>
                        {location ? location : <>&#8212; &#8212;</>}
                      </div>
                    </div>
                    <div className={searchPopoverItemEnd}>
                      {hotelCount && roomCount ? (
                        <>
                          <Text type='P3' colors='white'>
                            {pluralize(hotelCount, "Hotel", "Hotels")}
                          </Text>
                          <Text type='P3' colors='white' weight='bold'>
                            {pluralize(roomCount, "Room", "Rooms")}
                          </Text>
                        </>
                      ) : (
                        <JoinTheWaitlist />
                      )}
                    </div>
                  </Ariakit.ComboboxItem>
                </a>
              </Link>
            );
          })}

          {noMatchingResults ? null : (
            <Ariakit.ComboboxItem
              as='div'
              className={searchPopoverItemSuggestEvent({ noDataFound: true })}
            >
              <Text type='P2' colors='white'>
                Can&apos;t find your event?{" "}
                <SuggestEvent
                  asText
                  text={
                    <>
                      <Text as='span' colors='green100'>
                        Click here to suggest one
                      </Text>
                    </>
                  }
                />
              </Text>
            </Ariakit.ComboboxItem>
          )}
        </div>
      </>
    );
  }

  // Valid response but no returned events
  return null;
};
