import { styled } from "@hoken/core/styles/stitches.config";

export const SuggestEventWrapper = styled("section", {
  p: {
    fontFamily: "$franklin",
    fontWeight: "400",
    fontSize: "$hoken-core-font-15",
    marginBottom: "0.75rem",
  },
});

export const SuggestEventButton = styled("div", {
  minWidth: "311px",
  textTransform: "uppercase",
  borderRadius: "2px",
  fontSize: "$small",
  padding: "1.18rem",
  lineHeight: "$base",
  fontWeight: "$bold",
  fontFamily: "$helvetica",
  letterSpacing: "0.05rem",
  cursor: "pointer",
  border: "solid 2px $hoken-core-secondary-color",
  color: "$hoken-core-secondary-color",
});

export const SuggestEventTitleWrapper = styled("section", {
  display: "flex",
  justifyContent: "space-between",
  color: "$white",
  alignItems: "baseline",
  "svg path": { fill: "$white" },
});
export const SuggestEventTitle = styled("h2", {
  fontFamily: "$helvetica",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-20",
  lineHeight: "$hoken-core-line-height-24",
});

export const SuggestEventDescWrapper = styled("div", {
  marginTop: "1.5rem",
});

export const SuggestEventDesc = styled("p", {
  color: "$white",
  fontFamily: "$franklin",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-15",
  lineHeight: "$hoken-core-line-height-25",
});
