import { useAuthentication } from "@hoken/core";

import { FeaturedEventsResponse } from "../event.interfaces";

import useSWR from "swr";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

export const useFeaturedEvents = () => {
  const { session } = useAuthentication();
  const token = session?.token;
  const endpoint = `${NEXT_PUBLIC_API}/api/v1/events?featured=true&per_page=250`;
  // TODO: Look into why shorthand property names causes an error on error retry
  const request = token ? { endpoint: endpoint, token: token } : null;

  const { data, error } = useSWR<FeaturedEventsResponse>(request);
  const isLoading = !data;
  return { data: data?.data, isLoading, error };
};
