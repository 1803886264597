import { DateTime } from "luxon";

export const formatDate = ({
  startDate,
  endDate,
}: {
  startDate: string | Date;
  endDate: string | Date;
}) => {
  const startDateWithTimezone = DateTime.fromJSDate(new Date(startDate)).setZone(
    "America/Los_Angeles",
  );
  const endDateWithTimezone = DateTime.fromJSDate(new Date(endDate)).setZone(
    "America/Los_Angeles",
  );

  const startDateMonth = startDateWithTimezone.toFormat("LLL");
  const endDateMonth = endDateWithTimezone.toFormat("LLL");

  if (startDateMonth === endDateMonth) {
    const startDateDay = startDateWithTimezone.toFormat("d");
    const endDateDay = endDateWithTimezone.toFormat("d");

    const startDateFormatted = startDateWithTimezone.toFormat("LLL d ");
    const endDateFormatted = endDateWithTimezone.toFormat("d");

    const isSameDay = startDateDay === endDateDay;

    return isSameDay ? startDateFormatted : `${startDateFormatted} - ${endDateFormatted}`;
  }

  const startDateFormatted = startDateWithTimezone.toFormat("LLL d -");
  const endDateFormatted = endDateWithTimezone.toFormat("LLL d");

  return `${startDateFormatted} ${endDateFormatted}`;
};
