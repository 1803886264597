import React, { useRef, useState } from "react";

// Components
import {
  Conditional,
  Dialog,
  DialogContent,
  DialogCrossClose,
  DialogTrigger,
} from "@hoken/core";

import { SuggestEventForm } from "./suggest-event-form/suggest-event-form";
import { SuggestEventFormRefObject, SuggestionStatus } from "./suggest-event.interfaces";
// Styles
import {
  SuggestEventButton,
  SuggestEventDesc,
  SuggestEventDescWrapper,
  SuggestEventTitle,
  SuggestEventTitleWrapper,
  SuggestEventWrapper,
} from "./suggest-event.styles";

export const SuggestEvent = ({
  asText,
  text,
}: {
  text?: React.ReactNode;
  asText?: boolean;
}): JSX.Element => {
  const [suggestionStatus, setSuggestionStatus] = useState<SuggestionStatus>("default");
  const suggestEventFormRef =
    useRef() as React.MutableRefObject<SuggestEventFormRefObject>;

  return (
    <Dialog
      onOpenChange={(isOpen) => {
        const closing = false;
        if (isOpen === closing) {
          if (suggestEventFormRef && suggestEventFormRef.current) {
            suggestEventFormRef.current.useForm().reset();
          }

          setSuggestionStatus("default");
        }
      }}
    >
      {asText && text ? (
        <DialogTrigger>{text}</DialogTrigger>
      ) : (
        <>
          <SuggestEventWrapper>
            <p>Not seeing your favorite event?</p>
            <DialogTrigger>
              <SuggestEventButton>Suggest an event</SuggestEventButton>
            </DialogTrigger>
          </SuggestEventWrapper>
        </>
      )}

      <DialogContent
        css={{
          padding: "$hoken-core-space-24",
          width: "100%",
          bottom: "0",
          left: "auto",
          top: "auto",
          transform: "translate(0, 0)",
          animation: "0",
          zIndex: "9",
          gradientBorder: {
            overrides: {
              backgroundColor: "$black",
              borderRadius: "0",
              border: "none",
              borderTop: "2px solid transparent",
            },
          },
          "@bp2": {
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "fit-content",
            width: "465px",
            animation:
              "500ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal none running k-eyOShd",
            gradientBorder: {
              overrides: {
                backgroundColor: "$black",
                borderRadius: "0",
                border: "0",
                borderTop: "4px solid transparent",
              },
            },
          },
        }}
      >
        <Conditional when={suggestionStatus === "default"}>
          <>
            <SuggestEventTitleWrapper>
              <SuggestEventTitle>Submit an event</SuggestEventTitle>
              <DialogCrossClose />
            </SuggestEventTitleWrapper>

            <SuggestEventDescWrapper>
              <SuggestEventDesc>
                We’re always on the search for the next big event. Let us know the details
                and we’ll get in touch when we have hotel rooms available for booking!
              </SuggestEventDesc>
            </SuggestEventDescWrapper>
            <SuggestEventForm
              ref={suggestEventFormRef}
              setSuggestionStatus={(status) => setSuggestionStatus(status)}
            />
          </>
        </Conditional>
        <Conditional when={suggestionStatus === "success"}>
          <>
            <SuggestEventTitleWrapper>
              <SuggestEventTitle>Thank you for your suggestion!</SuggestEventTitle>
              <DialogCrossClose />
            </SuggestEventTitleWrapper>

            <SuggestEventDescWrapper>
              <SuggestEventDesc>
                Our curatorial team will review your submission and you’ll be added to the
                waitlist if your event launches on Hoken!
              </SuggestEventDesc>
            </SuggestEventDescWrapper>
          </>
        </Conditional>
      </DialogContent>
    </Dialog>
  );
};
