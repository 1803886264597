import { text } from "@hoken/design-system/texts/text.vanilla.css";
import { breakpoints, varsHoken } from "@hoken/design-system/themes";
import { keyframes, style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";

export const searchContainer = recipe({
  base: {
    margin: "auto",
    display: "flex",
    minHeight: "45px",
    width: "100%",
    maxWidth: "860px",
    flexDirection: "row",
    backgroundColor: varsHoken.colors.black,
    position: "relative",
    justifyContent: "space-between",
    transition: "all .2s ease-in",
    borderRadius: varsHoken.radius.small,
    border: `1px ${varsHoken.colors.green100} solid`,
    padding: `0 ${varsHoken.space.medium} 0 ${varsHoken.space.medium}`,
    selectors: {
      "&:focus-within": {
        borderColor: varsHoken.colors.gray800,
        outline: "none",
      },
    },
    "@media": {
      [breakpoints.sm]: {
        height: "3.5rem",
      },
    },
  },
  variants: {
    themeLight: {
      true: {
        backgroundColor: varsHoken.colors.white,
        border: `1px ${varsHoken.colors.primary} solid`,
        selectors: {
          "&:focus-within": {
            borderColor: varsHoken.colors.gray800,
            outline: "none",
          },
        },
      },
    },
  },
});

export const searchInput = style([
  text.classNames.variants.type.H4Small,
  {
    // Fix alignment caused by helvetica font
    paddingTop: "3px",
    border: "none",
    color: varsHoken.colors.white,
    flexGrow: "1",
    backgroundColor: "transparent",
    caretColor: varsHoken.colors.green100,

    //header_h5
    "@media": {
      [breakpoints.sm]: {
        // ...header_h3,
        paddingTop: "5px",
        selectors: {
          "&::placeholder": {
            // ...header_h3,
          },
        },
      },
    },
    selectors: {
      "&::placeholder": {
        color: varsHoken.colors.white,
      },
      // Resolve issue where placeholder text does not disappear on input focus cc:https://stackoverflow.com/questions/12821746/clear-html5-placeholder-attribute-text-on-focus-in-chrome
      "&:focus::-webkit-input-placeholder": {
        color: "transparent!important",
      },
      "&:input:focus::-moz-placeholder": {
        color: "transparent!important",
      },
      "&:input:focus:-moz-placeholder": {
        color: "transparent!important",
      },
      "&:focus, &:focus-visible": {
        outline: "none",
      },
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        opacity: 0,
        WebkitAppearance: "none",
        margin: 0,
      },
      //If container theme is light
      [`${searchContainer.classNames.variants.themeLight.true} &`]: {
        color: varsHoken.colors.black,
        caretColor: varsHoken.colors.primary,
      },
      [`${searchContainer.classNames.variants.themeLight.true} &::placeholder`]: {
        color: varsHoken.colors.gray700,
      },
    },
  },
]);

export const searchPopoverContainer = style({
  zIndex: 3,
  display: "flex",
  flexDirection: "column",
  gap: varsHoken.space.small,
  backgroundColor: varsHoken.colors.gray950,
  minWidth: "311px",
  maxHeight: "400px",
  overflowY: "scroll",
  marginTop: varsHoken.space.xsmall,
  opacity: 0,
  paddingLeft: `${varsHoken.space.medium}`,
  transition: "all .150s ease-in",
  /**
   * Fix alignment with popover width and input. This reduction in margin is
   * calculating a negative margin equal to the search inputs border and padding
   */
  marginLeft: "-17px",
  marginRight: "-17px",
  "@media": {
    [breakpoints.sm]: {
      marginTop: 0,
      border: "none",
      borderTop: "none",
      selectors: {
        "&[data-enter]": {
          border: `${varsHoken.colors.gray800} solid 1px`,
          borderTop: "none",
          transform: "translateY(-1px)",
        },
      },
    },
  },

  selectors: {
    "&[data-enter]": {
      opacity: 1,
      transform: "translateY(0px)",
    },
    "&[data-leave]": {
      transition: "all 0s ease-in",
      transform: "translateY(4px)",
      pointerEvents: "none",
      display: "none",
    },
  },
});

export const searchPopoverItemContainer = style({
  display: "flex",
  flexDirection: "column",
  paddingRight: varsHoken.space.medium,
});

export const searchPopoverItem = style({
  borderBottom: `${varsHoken.colors.gray800} solid 1px`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: `${varsHoken.space.large} 0`,
  alignItems: "flex-start",
  gap: varsHoken.space.small,

  selectors: {
    "&:active": {
      transition: "all .150s ease-in",
      transform: "scale(1.05)",
    },
  },
  "@media": {
    [breakpoints.sm]: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: `${varsHoken.space.large} 0`,
      marginLeft: "-1rem",
      paddingLeft: "1rem",
      marginRight: "-1rem",
      paddingRight: "1rem;",
      gap: varsHoken.space.medium,
      selectors: {
        "&:hover": {
          backgroundColor: "rgba(10, 10, 26, .8)",
        },
      },
    },
  },
});

export const searchPopoverItemStart = style({
  flexGrow: 0,
  flexShrink: 0,
});

export const searchPopoverItemText = recipe({
  base: {
    fontFamily: varsHoken.fonts.family.helvetica,
    lineHeight: "120%",
  },
  variants: {
    typography: {
      dateRange: {
        fontSize: varsHoken.fonts.sizes[10],
        color: varsHoken.colors.white,
        fontWeight: "bold",
        textTransform: "uppercase",
        width: "70px",
        "@media": {
          [breakpoints.sm]: {
            flexGrow: 0,
            flexShrink: 0,
          },
        },
      },
      featured: {
        fontSize: varsHoken.fonts.sizes[14],
        color: varsHoken.colors.primary200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        webkitLineClamp: "1",
        webkitBoxOrient: "vertical",
        "@media": {
          [breakpoints.sm]: {
            fontSize: varsHoken.fonts.sizes[16],
          },
        },
      },
      event: {
        fontSize: varsHoken.fonts.sizes[14],
        color: varsHoken.colors.green100,
        width: "100%",
        flexGrow: 0,
        flexShrink: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        "@media": {
          [breakpoints.sm]: {
            width: "230px",
            fontSize: varsHoken.fonts.sizes[16],
          },
        },
      },
      location: {
        fontSize: varsHoken.fonts.sizes[12],
        color: varsHoken.colors.gray600,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        width: "100%",
        "@media": {
          [breakpoints.sm]: {
            width: "130px",
          },
        },
      },
    },
  },
});

export const searchPopoverItemCenter = style({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",

  fontFamily: varsHoken.fonts.family.helvetica,
  fontSize: varsHoken.fonts.sizes[10],
  lineHeight: "120%",
  color: varsHoken.colors.white,
  "@media": {
    [breakpoints.sm]: {
      flexDirection: "row",
      gap: varsHoken.space.small,
    },
  },
});

export const searchPopoverItemEnd = style({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  minWidth: "51px",
  textAlign: "left",
  alignItems: "start",
  gap: varsHoken.space.small,
  "@media": {
    [breakpoints.sm]: {
      width: "200px",
      textAlign: "right",
      alignItems: "end",
      justifyContent: "flex-end",
      gap: varsHoken.space.small,
    },
  },
});

export const searchPopoverItemDivider = style({
  "@media": {
    [breakpoints.sm]: {
      width: "1px",
      height: "11px",
      backgroundColor: varsHoken.colors.gray750,
    },
  },
});

export const searchPopoverItemSuggestEvent = recipe({
  base: {
    minHeight: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: varsHoken.fonts.sizes.medium,
    color: varsHoken.colors.white,
    fontWeight: "normal",
    fontFamily: varsHoken.fonts.family.helvetica,
    gap: ".5rem",
    flexDirection: "column",
  },

  variants: {
    noDataFound: {
      true: {
        flexWrap: "wrap",
      },
    },
  },
});

export const searchPopoverItemTitle = style({
  fontFamily: varsHoken.fonts.family.helvetica,
  fontWeight: "normal",
  fontSize: varsHoken.fonts.sizes[12],
  letterSpacing: "0.68em",
  textTransform: "uppercase",
  paddingTop: varsHoken.space.large,

  background: "linear-gradient(100.7deg, #5BFCB8 1.68%, #857AFF 50%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
});

export const searchPopoverContainerList = style({
  zIndex: 3,
  display: "flex",
  flexDirection: "column",
  gap: varsHoken.space.small,
  backgroundColor: varsHoken.colors.gray950,
  minWidth: "311px",
  maxWidth: "570px",
  marginTop: varsHoken.space.xsmall,
  opacity: 1,
  margin: "auto",
  background: "transparent",
  maxHeight: "inherit",
  overflow: "visible",
  padding: 0,
  transition: "all .150s ease-in",
  // div: {
  //   paddingRight: 0,
  // },
  selectors: {
    "&[data-enter]": {
      opacity: 1,
      transform: "translateY(0px)",
    },
    "&[data-leave]": {
      transition: "all 0s ease-in",
      transform: "translateY(4px)",
      pointerEvents: "none",
      // gradientBorder: {
      //   overrides: {
      //     borderRadius: "$hoken-core-border-radius-4",
      //     backgroundColor: "$black",
      //     borderTop: "none",
      //   },
      // },
    },
  },
  "@media": {
    [breakpoints.sm]: {
      marginTop: 0,
      border: "none",
      borderTop: "none",
      marginRight: "-41px",
      selectors: {
        "&[data-enter]": {
          border: "$hoken-core-greyscale-80 solid 2px",
          borderTop: "none",
          transform: "translateY(-1px)",
        },
      },
    },
  },
});

export const searchButtonContainer = style({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  height: "100%",
  paddingRight: varsHoken.space.small,
  right: 0,
  top: 0,
});

export const searchButton = style({
  transition: "opacity .2s ease-in",
  selectors: {
    "input:not([value=''])+* &": {
      outline: "none",
    },
    [`${searchContainer.classNames.variants.themeLight.true} &`]: {
      color: varsHoken.colors.white,
      backgroundColor: varsHoken.colors.primary,
    },
  },
});

export const searchSpinnerContainer = style({
  width: "31px",
  height: "41px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const spinAnimation = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const searchSpinnerWrapper = style({
  animation: `${spinAnimation} .8s linear infinite`,
});

//Search page
export const searchPageWrapper = style({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: "auto",
  minHeight: "100vh",
  width: "100vw",
  backgroundColor: varsHoken.colors.black,
  gap: varsHoken.space.medium,
});
