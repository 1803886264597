import { forwardRef } from "react";

import { Box, Text, fetcher, useUser } from "@hoken/core";
import isDev from "@hoken/core/utilities/isDev";

import { SuggestAnEventFormProps } from "../suggest-event.interfaces";
import { SuggestionStatus } from "../suggest-event.interfaces";
import { SuggestEventFormMocks } from "./suggest-event-form.mock";
// Styles
import {
  SuggestEventFormField,
  SuggestEventFormInput,
  SuggestEventFormInputName,
  SuggestEventFormSubmit,
  SuggestEventFormWrapper,
} from "./suggest-event-form.styles";

import { useForm } from "react-hook-form";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;
const endpoint = `${NEXT_PUBLIC_API}/api/v1/suggest-event`;

export const SuggestEventForm = forwardRef(
  ({
    setSuggestionStatus,
  }: {
    setSuggestionStatus: (status: SuggestionStatus) => void;
  }): JSX.Element => {
    const { register, handleSubmit, formState } = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
    });
    const { token } = useUser();

    const suggestAnEvent = async (data: SuggestAnEventFormProps) => {
      try {
        const email = data.email?.toLowerCase();
        const userToken = token as string;

        const request: {
          endpoint: string;
          token: string;
        } = {
          endpoint: endpoint,
          token: userToken,
        };

        const options: RequestInit = {
          method: "POST",
          body: JSON.stringify({
            event_name: data.eventName,
            email: email,
            city: data.city,
            event_url: data.eventUrl,
          }),
        };

        const response = await fetcher(request, options);
        /* istanbul ignore next */
        if (isDev) {
          console.log("Adding user event suggestion:", response);
        }

        setSuggestionStatus("success");
      } catch (error) {
        setSuggestionStatus("error");
      }
    };

    const stopPropagationHandler = (event: React.FormEvent) => {
      event.stopPropagation();
    };

    return (
      <SuggestEventFormWrapper onSubmit={handleSubmit(suggestAnEvent)}>
        {SuggestEventFormMocks?.map((field) => (
          <SuggestEventFormField key={field.name}>
            <SuggestEventFormInputName>{field.name}</SuggestEventFormInputName>
            <SuggestEventFormInput
              noCapitalize={true}
              placeholder={field.placeholder}
              type={field.type}
              onKeyDown={stopPropagationHandler}
              {...register(field.inputName, {
                required: field.isRequired,
                pattern: field.validations?.pattern && {
                  value: field.validations.pattern.value,
                  message: field.validations.pattern.message,
                },
              })}
            />
          </SuggestEventFormField>
        ))}
        <Box>
          <Text
            css={{
              marginTop: "6px",
            }}
            color='Brand_dark_primary'
            typography='Paragraph_P3'
          >
            We will contact you when we have rooms for this event
          </Text>
        </Box>
        <SuggestEventFormSubmit disabled={!formState.isValid}>
          Submit
        </SuggestEventFormSubmit>
      </SuggestEventFormWrapper>
    );
  },
);

SuggestEventForm.displayName = "SuggestEventForm";
