import {
  Box,
  Link,
  SuggestEvent,
  formatDate,
  formatLocation,
  pluralize,
} from "@hoken/core";

import { JoinTheWaitlist } from "./join-the-waitlist";
import { SearchPopoverProps } from "./search.interfaces";
import {
  searchPopoverItem,
  searchPopoverItemCenter,
  searchPopoverItemContainer,
  searchPopoverItemDivider,
  searchPopoverItemEnd,
  searchPopoverItemStart,
  searchPopoverItemSuggestEvent,
  searchPopoverItemText,
} from "./search.vanilla.css";
import { useSearch } from "./useSearch";

import * as Ariakit from "@ariakit/react";
import { Text } from "@hoken/design-system";

export const SearchPopover = ({ query }: SearchPopoverProps) => {
  const { data, isLoading, isValidating } = useSearch(query);

  if (!query) {
    return null;
  }

  if ((isLoading || isValidating) && data === undefined) {
    return null;
  }

  const noMatchingResults = data?.length === 0;
  const matchingResults = data?.length > 0;
  const origin =
    typeof window !== "undefined" && window.location.origin ? window.location.origin : "";

  if (noMatchingResults) {
    return (
      <Ariakit.ComboboxItem
        as='div'
        className={searchPopoverItemSuggestEvent({ noDataFound: true })}
      >
        <Text type='P2' colors='white'>
          Unfortunately, we couldn&apos;t find anything matching your search.
        </Text>
        <Box
          css={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <SuggestEvent
            asText
            text={
              <>
                <Text as='span' colors='green100'>
                  Click here to suggest an event
                </Text>
              </>
            }
          />
        </Box>
      </Ariakit.ComboboxItem>
    );
  }

  if (matchingResults) {
    return (
      <>
        <div className={searchPopoverItemContainer}>
          {data.map((searchResult, index) => {
            const id = searchResult?.event_id;
            const name = searchResult?.event_name;
            const startDate = searchResult?.event_start_at;
            const endDate = searchResult?.event_end_at;
            const dateRange = formatDate({ startDate, endDate }).replace(
              /(\b\d+)\s+\-\s+(\d+\b)/g,
              "$1-$2",
            );
            const city = searchResult?.venue_city;
            const state = searchResult?.venue_state;
            const location = formatLocation({ city, state });
            const hotelCount = searchResult?.hotel_count;
            const roomCount = searchResult?.room_count;
            const encodedName = encodeURIComponent(
              name?.replace(/ /gi, "-").toLowerCase(),
            );
            const encodedId = encodeURI(id);
            // Absolute routes to allow search to be rendered on any route
            const eventHref = `${origin}/events/${encodedName}/${encodedId}`;

            return (
              <Link key={`${id}-${index}`} href={eventHref} legacyBehavior>
                <a>
                  <Ariakit.ComboboxItem className={searchPopoverItem}>
                    <div className={searchPopoverItemStart}>
                      <div className={searchPopoverItemText({ typography: "dateRange" })}>
                        {dateRange ? dateRange : <>&#8212; &#8212;</>}
                      </div>
                    </div>
                    <div className={searchPopoverItemCenter}>
                      <div className={searchPopoverItemText({ typography: "event" })}>
                        {name}
                      </div>
                      <div className={searchPopoverItemDivider} />
                      <div className={searchPopoverItemText({ typography: "location" })}>
                        {location ? location : <>&#8212; &#8212;</>}
                      </div>
                    </div>
                    <div className={searchPopoverItemEnd}>
                      {hotelCount && roomCount ? (
                        <>
                          <Text type='P3' colors='white'>
                            {pluralize(hotelCount, "Hotel", "Hotels")}
                          </Text>
                          <Text type='P3' colors='white' weight='bold'>
                            {pluralize(roomCount, "Room", "Rooms")}
                          </Text>
                        </>
                      ) : (
                        <JoinTheWaitlist />
                      )}
                    </div>
                  </Ariakit.ComboboxItem>
                </a>
              </Link>
            );
          })}
        </div>
      </>
    );
  }

  return null;
};
