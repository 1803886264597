import { styled } from "@hoken/core/styles/stitches.config";

export const SuggestEventFormWrapper = styled("form", {});

export const SuggestEventFormField = styled("div", {
  marginTop: "1.5rem",
});

export const SuggestEventFormInputName = styled("p", {
  color: "$white",
  fontFamily: "$franklin",
  fontSize: "$hoken-core-font-15",
  marginBottom: "0.25rem",
});

export const SuggestEventFormInput = styled("input", {
  borderRadius: "$rounded",
  fontSize: ".95rem",
  width: "100%",
  padding: ".865rem 1rem",
  background: "#1E1E23",
  outline: "#1E1E23",
  color: "$white",
  "&::placeholder": {
    textTransform: "capitalize",
    color: "#817F8F",
    fontFamily: "$franklin",
    fontSize: "$hoken-core-font-15",
  },

  variants: {
    noCapitalize: {
      true: {
        "&::placeholder": {
          textTransform: "none",
        },
      },
    },
  },
});

export const SuggestEventFormSubmit = styled("button", {
  marginTop: "1.5rem",
  fontSize: "$hoken-core-font-13",
  fontFamily: "$helvetica",
  color: "#1E1E23",
  letterSpacing: "0.05rem",
  textTransform: "uppercase",
  borderRadius: "2px",
  padding: "1rem",
  width: "100%",
  fontWeight: "bold",
  background: "$green",
  "&:disabled": {
    background: "#817F8F",
  },
});
