import { MAX_SEARCH_RESULT_EVENTS, sanitizeText, useAuthentication } from "@hoken/core";

import { SearchEventResponse, UseSearchArguments } from "./search.interfaces";

// @ts-expect-error https://github.com/uidotdev/usehooks/issues/185#issuecomment-1573927460
import { useDebounce } from "@uidotdev/usehooks";
import useSWR from "swr";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

export const useSearch = (
  query: UseSearchArguments["query"],
  options?: UseSearchArguments["options"],
) => {
  const debounce = options?.debounce || 200;
  const sanitizedQuery = sanitizeText(query || "");
  const debouncedQuery = useDebounce(sanitizedQuery, debounce);
  const { session } = useAuthentication();
  const token = session?.token;

  const endpoint = `${NEXT_PUBLIC_API}/api/v1/search/events?q=${debouncedQuery}&limit=${MAX_SEARCH_RESULT_EVENTS}`;
  // TODO: Look into why shorthand property names causes an error on error retry
  const request = token && debouncedQuery ? { endpoint: endpoint, token: token } : null;

  /**
   * isValidating becomes true whenever there is an ongoing request whether the data is loaded or not
   * isLoading becomes true when there is an ongoing request and data is not loaded yet.
   */
  const { data, isLoading, isValidating, error } = useSWR<SearchEventResponse>(request, {
    // https://swr.vercel.app/docs/advanced/understanding.en-US#return-previous-data-for-better-ux
    // support real-time search when typing, keeping the previous fetched data while in a is loading
    keepPreviousData: true,
  });

  return { data: data?.data?.results, isLoading, isValidating, error };
};
