import { Text } from "@hoken/core";

export const JoinTheWaitlist = () => {
  return (
    <Text
      typography='Paragraph_P3'
      weight='bold'
      css={{
        background:
          "linear-gradient(90deg, rgba(91,252,184,1) 0%, rgba(133,122,255,1) 100%)",
        color: "transparent",
        cursor: "pointer",
        "-webkit-background-clip": "text",
        "background-clip": "text",
      }}
    >
      Join the waitlist
    </Text>
  );
};
