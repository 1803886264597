import { useEffect, useState } from "react";

import { useBreakpoint } from "@hoken/core";
import { Image } from "@hoken/core";

import { SearchPopoverProps } from "./search.interfaces";
import {
  searchButton,
  searchButtonContainer,
  searchSpinnerContainer,
  searchSpinnerWrapper,
} from "./search.vanilla.css";
import { useSearch } from "./useSearch";

import { Icons } from "@hoken/design-system";
import { Button } from "@hoken/design-system";

export const SearchInputIcon = ({ query }: SearchPopoverProps) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { isValidating } = useSearch(query);
  const delayInMilliseconds = 500;
  const isDesktop = useBreakpoint("isDesktop");

  useEffect(() => {
    if (isValidating) {
      setShowLoadingIcon(true);
    }

    new Promise<void>((resolve) => {
      setTimeout(() => {
        if (!isValidating) {
          setShowLoadingIcon(false);
        }
        resolve();
      }, delayInMilliseconds);
    });
  }, [isValidating, setShowLoadingIcon]);

  if (showLoadingIcon) {
    return (
      <div className={searchButtonContainer}>
        <div className={searchSpinnerContainer}>
          <Image
            src='/images/temp/search-spinner.png'
            width='40'
            height='40'
            className={searchSpinnerWrapper}
            alt='Searching for events loading icon'
            style={{ width: "18px", height: "18px" }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={searchButtonContainer}>
      <Button
        type='brand_primary_dark'
        size={isDesktop ? "medium" : "small"}
        className={searchButton}
      >
        <Icons.Search alt='search' />
      </Button>
    </div>
  );
};
